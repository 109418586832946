import { useEffect, useMemo, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Configurator, { CartItemState } from './Configurator';

import './App.css';
import { getApiCartItem } from '../cart/utils';

function App() {
  const cartItemId = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    const cartItemId = params.get('cart_item_id') || undefined;
    return cartItemId;
  }, []);
  const [cartItem, setCartItem] = useState<CartItemState>();

  useEffect(() => {
    if (!cartItemId) return;

    const getAndSetCartItem = async (cartItemId: string) => {
      const cartItem = await getApiCartItem(cartItemId);
      setCartItem(cartItem);
    };

    getAndSetCartItem(cartItemId);
  }, [cartItemId]);

  return <Configurator state={cartItem} cartItemId={cartItemId} />;
}

export default App;
